@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Kalameh;
  src: url("/public/fonts/KalamehWeb-Regular.woff");
}
@font-face {
  font-family: KalamehMed;
  src: url("/public/fonts/KalamehWeb-Medium.woff");
}
@font-face {
  font-family: KalamehSemi;
  src: url("/public/fonts/KalamehWeb-SemiBold.woff");
}

html {
  scroll-behavior: smooth;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.datePickerStyle {
  width: 100%;
  background-color: black;
}
button,
button:focus,
textarea {
  outline: none;
}

input:focus,
button:focus,
select:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
* {
  font-family: "kalameh" !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  background-color: #252525;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.dir-ltr {
  direction: ltr;
}
.big-circle {
  position: fixed;
  top: 60vh; /* 30vh پایین‌تر از پایین صفحه */
  left: 50%; /* در وسط صفحه افقی */
  transform: translateX(-50%); /* تنظیم موقعیت در وسط صفحه افقی */
  width: 170vw; /* عرض بیشتر از عرض صفحه */
  height: 100vw; /* ارتفاع برابر با عرض بیشتر از عرض صفحه */
  border-radius: 50%; /* تبدیل به دایره */
  background-color: #fff; /* رنگ پس‌زمینه */
  z-index: 15;
}
.bg-glass {
  box-shadow: 0px 4px 12px 0px #00000040;
  background-color: #ffffffa2;
}
.purple-line {
  width: 200px;
  height: 1px;
  background: linear-gradient(to right, #f9f2fb 0%, #482d4f 100%);
}
.bg-glass-absolute {
  width: 101%;
  height: 110%;
  box-shadow: 0px 4px 12px 0px #00000040;
  background-color: #e9e9e99c;
  position: absolute;
  bottom: -20px;
  border-radius: 20px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
}
.ql-container,
.ql-editor {
  min-height: 400px;
  color: #fff !important;
  text-align: right !important;
}
.ql-editor.ql-blank::before {
  color: #fff !important;
}

.rmdp-input {
  border-color: #dee2e6 !important;
  width: 130px !important;
  height: 48px !important;
  padding-right: 16px !important;
  color: #111;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1170px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1300px;
  }
}
@media (min-width: 1500px) {
  .container {
    max-width: 1400px;
  }
}
@media (min-width: 1636px) {
  .container {
    max-width: 1650px;
  }
}
